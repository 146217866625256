.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    color: white;
}


nav{
    width: 100%;
    padding-left: 12.5px;
    padding-right: 12.5px;
    background-color: #1b70cf;
    display: flex;
    gap: 1rem; 
    height: 2.3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-ul{
    list-style-type: none;
    display: flex;
    gap: 1rem;
    flex: 1;
}

.current{
    text-decoration: underline !important;
    font-weight: bold;
    background-color: #45a14d; /* Light gray background */
    border-bottom: 2px solid #000000; /* Blue underline */
    color: #FFFFFF; /* Blue text */
}
  
.nav-logo {
    font-size: 1.5rem;
}

.nav-item{
    flex-grow: 1; 
}
  
.nav-item a {
    display: block; 
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    
    text-align: center;
}
  
  .nav-item a:hover {
    background-color: #4caf52;
  }