.header{
    width: 100%;
}

.more{
    display: flex;
    justify-content: flex-end;
}

.zone4-logo{
  height: 1.5em;
  padding-left: 25px;
}

.zone4Title {
    display: flex;
    align-items: center;
  }
  
  .title {
    margin-right: auto; /* Push the "more" div to the right */
  }
  
  .more {
    cursor: pointer;
  }
  
  .dropdown-content {
    position: absolute;
    top: 6%; /* Position it just below the "more" div */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1; /* Ensure dropdown appears above other elements */
  }
  
  /* Show dropdown when its parent (.zone4Title) is hovered */
  .zone4Title:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content span {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: #333;
  }
  
  .dropdown-content span:hover {
    background-color: #f1f1f1;
  }
  