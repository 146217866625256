#popup-csvData {
    width: 103%;
    border-right: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }
  
  #csvData table{
    /* overflow: scroll; */
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
  }

  #csvData thead tr{
    border: none;
  }
  
  #csvData tr{
    border-bottom: 1px solid black;
  }

  #table-wraper{
    max-width: 100%;
    overflow-x: scroll;
  }

  #table-wraper h4 {
    margin-left: 25px;
  }

  th {
    background-color: lightgray;
    color: white;
    padding: 10px;
    color:rgba(0, 0, 0, 0.661);
  }
  
  tr {
    background-color: white;
    color: black;
    padding: 10px;
    color:rgba(0, 0, 0, 0.645);
    width: 100%;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  }

  td {
    text-align: center;
  }

.name {
  text-align: right;
  padding-right: 1.12em;
}

.red{
  /* background-color: green; */
  background-color: #f8663a;
  text-decoration: line-through;
}

.green{
    /* background-color: green; */
    background-color: #94ef94;
}

#popup-csvData {
    position: fixed;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
    top: 3%;
    bottom: 5%;
    overflow: scroll;
    flex-direction: column;
    display: none;
    border: 4px solid grey;
}

.crossed-out {
    text-decoration: line-through;
}