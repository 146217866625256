#addJurryDropDown {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

tr {
  background-color: transparent !important;
}

td {
  text-align: justify !important;
}

#addJurryDropDown .outerDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Jurry-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#defultJurryMessage {
  font-size: 1.1em;
  color: #777474;
  font-weight: bold;
}

.deleteJurry {
  width: 90px;
  height: 25px;
}

#addJurryDropDown {
  border: 2px solid rgb(147, 146, 146);
  background-color: lightgrey;
  padding: 10px;
  margin-right: 15px;
  border-radius: 5px;
}

#addJurryDropDown label {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #addJurryDropDown {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  #addJurryDropDown .outerDiv{
    flex-direction: column;
    align-items: flex-start;
  }

  #Function{
    max-width: 150px;
  }

  #jurry-add{
    display: flex;
    align-self: center;
  }
}
#zone4FISXML input{
    padding: 10px;
    /* background-color: red; */
    width: 100%;
    display: flex;
  }

  input, select {
    margin: 10px;
  }

  select, input {
    background-color: white;
    border: 1px solid grey;
    /* padding: 0 1em 0 0; */
    margin: 0;
    font-family: inherit;
    /* font-size: inherit; */
    cursor: inherit;
    line-height: inherit;
    /* z-index: 1; */
    outline: none;
  }

  #addJurryDropDown input{
    outline: 1px solid grey;
    width: 70%;
    margin: 10px;
  }