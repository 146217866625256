.fixed-width-box {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 50%; */
  max-width: 100%;
  height: 100%;
}

.Centerd-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: scroll;
}

button {
  margin: 10px;
  border: none;
  background-color: #424242;
  color: white;
  width: 200px;
  height: 40px;
  font-weight: bold;
}

.rendering-test{
  display: none;
}

button:hover {
  background-color: #353535;
  color: white;
}

.smallButton {
  margin: 10px;
  border: none;
  background-color: #424242;
  color: white;
  width: 100px;
  height: 30px;
}

.instr {
  margin: 20px;
  /* margin-left: 40px; */
  /* margin-right: 40px; */
  font-size: 0.9em;
  color: #777474;
  font-weight: bold;
}

@media screen and (max-width: 375px) {
  .fixed-width-box {
    max-width: 80%;
  }
}