#HeaderInfo label {
  font-weight: bold;
}

#HeaderInfo input {
  height: 30px;
  font-size: 0.9em;
  text-align: center;
  border: 2px solid rgb(147, 146, 146);
  border-radius: 5px;
  margin: 2px;
  padding: 0.5px;
}
#HeaderInfo select {
  height: 30px;
  font-size: 0.9em;
  text-align: center;
  border: 2px solid rgb(147, 146, 146);
  border-radius: 5px;
  margin: 2px;
  padding: 0.5px;
}

#headerInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#HeaderInfo label {
  margin-right: 10px;
}

.headerInfoSubmit,
#downloadButton {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#headerInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

table {
  margin-bottom: 0px;
}

.sub-title{
  background-color: #888888;
  height:2em;
  min-width: 25em;
  display: flex;
  align-items: center;
  padding-left: 1.1em;
  font-weight: 500;
  color: white;
}

.sub-title-div{
  padding-right:1.0em;
}

@media screen and (max-width: 375px) {
  tr{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
