.zone4Title{
    background-color: #666666;
    color: white;
    width: 100%;
    height: 40px;
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    padding-left: 25px;
    border-bottom: 1px solid black;
}

.zone4HeaderTitle{
    background-color: #2780e3;
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    padding-left: 25px;
    border-bottom: 1px solid black;
}

.zone4Title h2{
    /* margin-right: 15px; */
    padding-left: 1.5em;
}

.zone4Title div{
    margin-right: 2.0em;
}

.zone4HeaderTitle h2{
    /* margin-right: 15px; */
    padding-left: 1.5em;
}

.zone4HeaderTitle div{
    margin-right: 2.0em;
}

.triangle-down{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    margin-top: 3px;
    vertical-align: middle;
    border-top: 8px solid;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    transition: transform 0.2s ease-in-out;
}

.rotate-90 {
    transform: rotate(-90deg);
}