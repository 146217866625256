#USSA-points-download{
    /* margin:30px; */
    display: flex;
    align-items: center;
    justify-self: center; 
    flex-direction: column;
}

.points-input{
    height: 25px;
    width: 50px;
    font-size: 0.9em;
    text-align: center;
    border: 2px solid rgb(147, 146, 146);
    border-radius: 5px;
    margin: 2px;
    padding: 0.5px;
}

.centerd-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.fourm{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.download-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}