#csvData {
    width: 98%;
    border-right: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }
  
  #csvData table{
    /* overflow: scroll; */
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
  }

  #csvData thead tr{
    border: none;
  }
  
  #csvData tr{
    border-bottom: 1px solid black;
  }

  #table-wraper{
    max-width: 100%;
    overflow-x: scroll;
  }

  #table-wraper h4 {
    margin-left: 25px;
  }

  th {
    background-color: lightgray;
    color: white;
    padding: 10px;
    color:rgba(0, 0, 0, 0.661);
  }
  
  tr {
    background-color: white;
    color: black;
    padding: 10px;
    color:rgba(0, 0, 0, 0.645);
    width: 100%;
  }

  td {
    text-align: center;
  }

.name {
  text-align: right;
  padding-right: 1.12em;
}