

#dataCleanUpBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#checkData {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* VVV  CALCULAE PENALTY VVV */
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
  overflow-y: auto;
  flex: 1;
}


#penaltySlider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#sliderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(147, 146, 146);
  border-radius: 5px;
}

.sliderBox {
  /* background-color: #2780e3; */
  border: 1px solid black;
  width: 300px;
  height: 50px;
  border-radius: 5px;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}

.sliderBox {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.sliderBox.active {
  color: white;
  background-color: rgba(66, 66, 66, 0.458);
}

#spaceBetween {
  margin-left: 20px;
  margin-right: 20px;
}

.Submit-Button-Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
