/* Main popup container */
#PenaltyPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
}

/* Main content box */
#PenaltyBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;  /* Center the box */
    max-width: 600px;
}

/* Input row styling */
.input-row {
    display: flex;
    margin-bottom: 10px;
    align-items: center; /* Vertically center items */
    width:320px;
    margin-left: 70px;
}

.input-row label {
    width: 70px;
    text-align: left;
}

.input-row input {
    width: 75px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.input-row select {
    width: 200px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

/* Button containers */
#calcPenality,
#buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -48px;
}

/* Top 5 racers table */
#Top5Racers {
    overflow: scroll;
}

/* Highlight for selected racers */
.highlight {
    background-color: #89ff2f;
}


